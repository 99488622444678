import { DEBOUNCE_MS, FEATURE_FLAGS } from "../constants"
import _, { debounce } from "lodash"

import { DEFAULT_PAGE_TITLE } from "./../constants"
import { useEffect } from "react"

// TODO: need to come back to fix this type; Currently as it is typed, doesn't provide much value
// There should be a way to type this so that we only pass in "fetch***List" action type.
// This way, we can be confident that this method is only being used for debouncing of search in the
// list view. If we wanted a debounce that worked differently somwhere else, we could type that differently
// so they don't get mixed up. In my first attempt, I was able to add the Action types correcty in this file,
// but ran into issues adding the "Dispatch" type in the component where this method is called. Need to investigate further
// type fnType = (value?: string) => void

// Debounce wih time in milliseconds: useful for search bar delay on imput
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const debounceFn: typeof debounce = (fn: any) => _.debounce(fn, DEBOUNCE_MS)

// function to help track the props that are triggering a rerender for a component, and usage
// https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const propTracker = (props: any, useRef: any, useEffect: any): void => {
  const prev = useRef(props)
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v]
      }
      return ps
    }, {})
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps)
    }
    prev.current = props
  })
}

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title || DEFAULT_PAGE_TITLE

    return () => {
      document.title = DEFAULT_PAGE_TITLE
    }
  }, [title])
}

export const mapFeatureFlagToVariableName = (featureFlagString: string): string => {
  switch (featureFlagString) {
    case FEATURE_FLAGS.USE_DEVCYCLE:
      return "isDevCycleFFEnabled"
    case FEATURE_FLAGS.ENABLE_PREDICTIVE_DEALS:
      return "isPredictiveDealsFFEnabled"
    default:
      throw new Error(`Unknown feature flag: ${featureFlagString}`)
  }
}
