import { Box, Button, Link, Typography, withStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearFeatureFlags,
  clearSaveFeatureFlagSuccess,
  fetchFeatureFlagByTerm,
  fetchFeatureFlags,
  updateFeatureFlags,
} from "../../../../../redux/actions/featureFlagActions"

import EntityAssignmentTable from "../../../../common/EntityAssignmentTable"
import ErrorMessages from "../../../../common/Errors"
import { FEATURE_FLAGS } from "../../../../../constants"
import SuccessMessage from "../../../../common/SuccessMessage"
import _ from "lodash"
import { clearErrors } from "../../../../../redux/actions/errorActions"
import styles from "../../../styles"
import { useSelector } from "react-redux"

const FeatureFlags = () => {
  const dispatch = useAppDispatch()

  const featureFlags = useSelector((state: RootState) => state.featureFlags.featureFlags)
  const isLoading = useSelector((state: RootState) => state.featureFlags.isLoading)
  const isSubmitting = useSelector((state: RootState) => state.featureFlags.isSubmitting)
  const updateFeatureFlagsSuccessMessage = useSelector(
    (state: RootState) => state.featureFlags.updateFeatureFlagsSuccessMessage
  )

  const [checkedFeatureFlags, setCheckedFeatureFlags] = useState([])

  const isDevCycleFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isDevCycleFFEnabled
  )

  useEffect(() => {
    dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.USE_DEVCYCLE))
    return () => {
      dispatch(clearFeatureFlags())
      dispatch(clearErrors())
    }
  }, [])

  useEffect(() => {
    if (isDevCycleFFEnabled === false) {
      dispatch(fetchFeatureFlags())
    }
  }, [isDevCycleFFEnabled])

  useEffect(() => {
    if (featureFlags.length > 0 && checkedFeatureFlags.length === 0) {
      setCheckedFeatureFlags(featureFlags.filter((featureFlag) => featureFlag.value === true))
    }
  }, [featureFlags.length])

  const handleUpdateFeatureFlagsSubmit = () => {
    const allFeatureFlags = _.cloneDeep(featureFlags)
    allFeatureFlags.map((featureFlag) => {
      const isFeatureFlagTrue = !!_.find(checkedFeatureFlags, { id: featureFlag.id })
      featureFlag.value = isFeatureFlagTrue
      return featureFlag
    })

    dispatch(updateFeatureFlags(allFeatureFlags))
  }

  return (
    <Box mt={3} mb={3}>
      <ErrorMessages />
      <SuccessMessage
        successMessage={updateFeatureFlagsSuccessMessage}
        closeSuccessMessage={() => dispatch(clearSaveFeatureFlagSuccess())}
      />
      {isDevCycleFFEnabled ? (
        <Typography variant="body1" color="textPrimary" data-testid={"use-dev-cycle-text"}>
          Feature Flags are now controlled in DevCycle, please{" "}
          <Link href="https://app.devcycle.com/" target="_blank" rel="noopener noreferrer">
            go there
          </Link>{" "}
          to modify Feature Flags
        </Typography>
      ) : (
        <>
          <EntityAssignmentTable
            label={"Feature Flags"}
            testId={"feature-flags-table"}
            listItems={featureFlags}
            checkedItems={checkedFeatureFlags}
            onChange={(newCheckedValues) => {
              setCheckedFeatureFlags(newCheckedValues)
            }}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isLoading || isSubmitting}
              data-testid={"feature-flags-update-button"}
              onClick={() => handleUpdateFeatureFlagsSubmit()}
            >
              Update Feature Flags
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}

export default withStyles(styles)(FeatureFlags)
