import {
  ArrowRight as ArrowRightIcon,
  Edit as EditIcon,
  Search as SearchIcon,
  Upload as UploadIcon,
} from "react-feather"
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core"
import { BreadCrumb, HeaderInfo } from "../../../../../redux/types/newHeaderTypes"
import {
  ENTITY_STATUSES,
  FEATURE_FLAGS,
  OPERATION_TYPES_ENP,
  PAGE_LIMIT,
  PERMISSIONS,
} from "../../../../../constants"
import React, { useCallback, useEffect, useState } from "react"
import { RootState, useAppDispatch } from "../../../../../redux/store"
import {
  clearDeals,
  closeSaveDealSuccess,
  fetchAndExportDeals,
  fetchDeals,
} from "../../../../../redux/actions/dealActions"
import { clearErrors, closeError } from "../../../../../redux/actions/errorActions"

import { Alert } from "@material-ui/lab"
import Can from "../../../../common/Can"
import { FetchListPayload } from "../../../../../redux/types/sharedTypes"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import Header from "../../../../NewHeader"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import PerfectScrollbar from "react-perfect-scrollbar"
import { PlusCircle as PlusCircleIcon } from "react-feather"
import { Link as RouterLink } from "react-router-dom"
import TablePaginationActions from "../../../../common/TablePaginationActions"
import _ from "lodash"
import clsx from "clsx"
import dayjs from "dayjs"
import { debounceFn } from "../../../../../helpers/utilityHelpers"
import { fetchFeatureFlagByTerm } from "../../../../../redux/actions/featureFlagActions"
import { numberToDollars } from "../../../../../helpers/formatterHelper"
import styles from "./styles"
import { useSelector } from "react-redux"

dayjs.extend(LocalizedFormat)

export const buildHeaderInfo = (): HeaderInfo => {
  const headerText = "All Deals"
  const headerButtons = [
    {
      name: "CREATE NEW DEAL",
      route: `/tools/deals/create`,
      permission: PERMISSIONS.TOOLS.DEAL.CREATE,
      disabled: false,
      icon: <PlusCircleIcon />,
    },
  ]

  const breadcrumbs: BreadCrumb[] = [
    {
      name: "Dashboard",
      route: "/",
    },
    {
      name: "Deals",
    },
  ]

  return {
    headerText,
    breadcrumbs,
    headerButtons,
  }
}

const DealsList = ({ classes }) => {
  const dispatch = useAppDispatch()

  const isLoading = useSelector((state: RootState) => state.deals.isLoading)
  const isLoadingDealsForExport = useSelector(
    (state: RootState) => state.deals.isLoadingDealsForExport
  )
  const deals = useSelector((state: RootState) => state.deals.deals)
  const dealSuccessMessage = useSelector((state: RootState) => state.deals.dealSuccessMessage)
  const errors = useSelector((state: RootState) => state.errors.generalErrors)

  const focusedCompanyName = useSelector(
    (state: RootState) => state.currentUser.currentUser.focusedCompany.name
  )
  const pager = useSelector((state: RootState) => state.deals.pager)

  const isPredictiveDealsFFEnabled = useSelector(
    (state: RootState) => state.featureFlags.isPredictiveDealsFFEnabled
  )

  const sortOptions = [
    {
      value: "id|DESC",
      label: "ID (descending)",
    },
    {
      value: "id|ASC",
      label: "ID (ascending)",
    },
    {
      value: "name|ASC",
      label: "Name (ascending)",
    },
    {
      value: "name|DESC",
      label: "Name (descending)",
    },
  ]

  const [query, setQuery] = useState("")
  const [dealsStatusFilter, setDealStatusFilter] = useState(ENTITY_STATUSES.ALL)
  const [sortByFilter, setSortByFilter] = useState({
    value: "id",
    order: "DESC",
  })
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT.DEFAULT)
  const [selectedPage, setSelectedPage] = useState(0)

  const dealsListStatusOptions = [
    { value: ENTITY_STATUSES.ALL, label: "All" },
    { value: ENTITY_STATUSES.ACTIVE, label: "Active" },
    { value: ENTITY_STATUSES.INACTIVE, label: "Inactive" },
  ]

  const fetchDealsArgsObj: FetchListPayload = {
    pagination: {
      limit: pageLimit,
      page: selectedPage,
    },
    sort: {
      value: sortByFilter.value,
      order: sortByFilter.order,
    },
    filters: {
      term: query,
      status: dealsStatusFilter,
    },
  }
  dealsStatusFilter === ENTITY_STATUSES.ALL && delete fetchDealsArgsObj.filters.status

  const dispatchFetchDeals = (dealsSearchTerm: string = query): void => {
    dispatch(
      fetchDeals({
        ...fetchDealsArgsObj,
        filters: {
          ...fetchDealsArgsObj.filters,
          term: dealsSearchTerm,
          //
        },
      })
    )
  }

  useEffect(dispatchFetchDeals, [dealsStatusFilter, pageLimit, sortByFilter, selectedPage])

  useEffect(() => {
    if (isPredictiveDealsFFEnabled === undefined) {
      dispatch(fetchFeatureFlagByTerm(FEATURE_FLAGS.ENABLE_PREDICTIVE_DEALS))
    }

    return () => {
      dispatch(clearErrors())
      dispatch(clearDeals())
      dispatch(closeSaveDealSuccess())
    }
  }, [])

  const debounceSearch = useCallback(debounceFn(dispatchFetchDeals), [
    dealsStatusFilter,
    pageLimit,
    sortByFilter,
  ])
  const handleStatusFilterChange = (e) => {
    setDealStatusFilter(e.target.value)
    setSelectedPage(0)
  }
  const handleQueryChange = (event) => {
    setQuery(event.target.value)
    debounceSearch(event.target.value)
    setSelectedPage(0)
  }
  const handleSortChange = (event) => {
    const [sortValue, orderValue] = event.target.value.split("|")
    setSortByFilter({
      value: sortValue,
      order: orderValue,
    })
    setSelectedPage(0)
  }
  const handlePageChange = (event, newPage) => {
    setSelectedPage(newPage)
  }
  const handleLimitChange = (event) => {
    setPageLimit(event.target.value)
    setSelectedPage(0)
  }

  const dispatchFetchAndExportDeals = async () => {
    dispatch(
      fetchAndExportDeals({
        ...fetchDealsArgsObj,
        pagination: {
          paginate: false,
        },
        focusedCompanyName: focusedCompanyName,
      })
    )
  }

  const { headerText, breadcrumbs, headerButtons } = buildHeaderInfo()

  return (
    <>
      <Header headerText={headerText} breadcrumbs={breadcrumbs} buttons={headerButtons} />
      <Box mt={3} mb={3}>
        {errors.length > 0 &&
          errors.map((error, i) => (
            <Alert severity="error" key={`${error}-${i}`} onClose={() => dispatch(closeError(i))}>
              {error}
            </Alert>
          ))}
        {dealSuccessMessage.length > 0 && (
          <Alert
            severity="success"
            key={dealSuccessMessage}
            onClose={() => dispatch(closeSaveDealSuccess())}
          >
            {dealSuccessMessage}
          </Alert>
        )}
        <Card data-testid="deals-list-table-container">
          <Box
            sx={{
              p: 2,
              pr: 0,
            }}
            minHeight={56}
            display="flex"
            alignItems="center"
          >
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              placeholder="Search deals"
              value={query}
              variant="outlined"
              data-testid={"deals-list-search-input"}
            />
            <TextField
              label="Filter By Status"
              name="statusFilter"
              className={classes.dealsStatusField}
              onChange={handleStatusFilterChange}
              select
              SelectProps={{ native: true }}
              value={dealsStatusFilter}
              variant="outlined"
              inputProps={{
                "data-testid": "deals-list-status-filter-input",
              }}
            >
              {dealsListStatusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              label="Sort By"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={`${sortByFilter.value}|${sortByFilter.order}`}
              variant="outlined"
              data-testid={"deals-list-sort-input"}
            >
              {sortOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <Tooltip title="Export Deals List">
              <IconButton
                component={Button}
                onClick={dispatchFetchAndExportDeals}
                disabled={isLoading || !deals.length || isLoadingDealsForExport}
                data-testid={"deals-list-export-csv-button"}
              >
                <SvgIcon fontSize="small">
                  <UploadIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Box flexGrow={1} />
            <TablePagination
              component="div"
              count={pager.totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={selectedPage}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
              data-testid="deals-list-pagination"
              ActionsComponent={TablePaginationActions}
            />
          </Box>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      width="8%"
                      data-testid={"deals-list-external-id-table-column-header"}
                    >
                      External ID
                    </TableCell>
                    <TableCell data-testid={"deals-list-name-table-column-header"}>Name</TableCell>
                    <TableCell data-testid={"deals-list-auction-type-table-column-header"}>
                      Auction Type
                    </TableCell>
                    <TableCell data-testid={"deals-list-bidder-table-column-header"}>
                      Buyer
                    </TableCell>
                    <TableCell width="8%" data-testid={"deals-list-wseat-ids-table-column-header"}>
                      W Seat(s)
                    </TableCell>
                    <TableCell data-testid={"deals-list-price-table-column-header"}>
                      Price
                    </TableCell>
                    <TableCell data-testid={"deals-list-media-type-table-column-header"}>
                      Media
                    </TableCell>
                    <TableCell data-testid={"deals-list-device-type-table-column-header"}>
                      Devices
                    </TableCell>
                    <Can
                      perform={[PERMISSIONS.TOOLS.DEAL.VIEW, PERMISSIONS.TOOLS.DEAL.UPDATE]}
                      yes={
                        <TableCell
                          width="9%"
                          align="center"
                          data-testid={"deals-list-actions-table-column-header"}
                        >
                          Actions
                        </TableCell>
                      }
                    />
                  </TableRow>
                </TableHead>
                {isLoading ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={11} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody data-testid={"deals-list-content"}>
                    {deals.map((deal) => {
                      const dataConnectedDeal = deal.dmpSegmentLogic !== OPERATION_TYPES_ENP.NONE
                      const isAudienceConnectedDeal = !!deal.externalAudienceId
                      const isPredictiveDeal = !!deal.predictiveAudienceId
                      const canCopy = deal.status !== ENTITY_STATUSES.ARCHIVED
                      return (
                        <TableRow hover key={deal.externalId}>
                          <TableCell>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/tools/deals/${deal.id}`}
                              variant="h6"
                              data-testid={`deals-list-external-id-${deal.id}`}
                            >
                              {deal.externalId}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`/tools/deals/${deal.id}`}
                              variant="h6"
                              data-testid={`deals-list-name-${deal.id}`}
                            >
                              {deal.name}
                            </Link>
                            {/* Deal Status Labels */}
                            <small
                              className={clsx({
                                [classes.label]: true,
                                [classes.dealStatusActive]: deal.status === ENTITY_STATUSES.ACTIVE,
                                [classes.dealStatusInactive]:
                                  deal.status === ENTITY_STATUSES.INACTIVE,
                              })}
                            >
                              {deal.status}
                            </small>
                            {/* Data Deal Label */}
                            {dataConnectedDeal && (
                              <small className={clsx(classes.label, classes.dataDeal)}>
                                Data Deal
                              </small>
                            )}
                            {/* Audience Connected Deal Label */}
                            {isAudienceConnectedDeal && (
                              <small
                                className={clsx(classes.label, classes.audienceDeal)}
                                data-testid={`deals-list-audience-deal-label-${deal.id}`}
                              >
                                Audience Deal
                              </small>
                            )}
                            {/* CMP Label */}
                            {deal.cmpCheck && (
                              <small className={clsx(classes.label, classes.cmpDeal)}>
                                CMP Deal
                              </small>
                            )}
                            {/* Predictive Deal Label */}
                            {isPredictiveDealsFFEnabled && isPredictiveDeal && (
                              <small
                                className={clsx(classes.label, classes.predictiveDeal)}
                                data-testid={`deals-list-predictive-deal-label-${deal.id}`}
                              >
                                Predictive Deal
                              </small>
                            )}
                          </TableCell>
                          <TableCell data-testid={`deals-list-auction-type-${deal.id}`}>
                            {_.startCase(deal.auctionType)}
                          </TableCell>
                          <TableCell data-testid={`deals-list-bidder-${deal.id}`}>
                            {deal.bidder.name}
                          </TableCell>
                          <TableCell data-testid={`deals-list-wseat-ids-${deal.id}`}>
                            {deal.wseatIds.join(", ")}
                          </TableCell>
                          <TableCell data-testid={`deals-list-price-${deal.id}`}>
                            {numberToDollars(deal.price)}
                          </TableCell>
                          <TableCell data-testid={`deals-list-media-type-${deal.id}`}>
                            {_.startCase(deal.mediaType)}
                          </TableCell>
                          <TableCell data-testid={`deals-list-device-type-${deal.id}`}>
                            {_.startCase(deal.deviceType)}
                          </TableCell>
                          <Can
                            perform={[
                              PERMISSIONS.TOOLS.DEAL.VIEW,
                              PERMISSIONS.TOOLS.DEAL.CREATE,
                              PERMISSIONS.TOOLS.DEAL.UPDATE,
                            ]}
                            yes={
                              <TableCell align="right" className={classes.actionIconSpacing}>
                                <Can
                                  perform={PERMISSIONS.TOOLS.DEAL.VIEW}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/tools/deals/${deal.id}`}
                                      data-testid={`deals-list-view-button-${deal.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <ArrowRightIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                <Can
                                  perform={PERMISSIONS.TOOLS.DEAL.UPDATE}
                                  yes={
                                    <IconButton
                                      component={RouterLink}
                                      to={`/tools/deals/${deal.id}/edit`}
                                      data-testid={`deals-list-edit-button-${deal.id}`}
                                    >
                                      <SvgIcon fontSize="small">
                                        <EditIcon />
                                      </SvgIcon>
                                    </IconButton>
                                  }
                                />
                                {canCopy && (
                                  <Can
                                    perform={PERMISSIONS.TOOLS.DEAL.CREATE}
                                    yes={
                                      <IconButton
                                        component={RouterLink}
                                        to={`/tools/deals/${deal.id}/copy`}
                                        data-testid={`deals-list-copy-button-${deal.id}`}
                                      >
                                        <SvgIcon fontSize="small">
                                          <FileCopyOutlinedIcon />
                                        </SvgIcon>
                                      </IconButton>
                                    }
                                  />
                                )}
                              </TableCell>
                            }
                          />
                        </TableRow>
                      )
                    })}
                  </TableBody>
                )}
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={pager.totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={selectedPage}
            rowsPerPage={pageLimit}
            rowsPerPageOptions={PAGE_LIMIT.OPTIONS}
            data-testid="deals-list-pagination"
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Box>
      <Box sx={{ height: "24px" }} />
    </>
  )
}

export default withStyles(styles)(DealsList)
